// HTMLViewer.vue
<template>
  <div class="html-viewer" @keydown.meta.k.prevent="showNoteInterface">
    <div v-if="loading">Loading...</div>
    <div v-else-if="error">Error: {{ error }}</div>
    <div v-else ref="content" v-html="markedContent" @mouseup="handleSelection"></div>

    <div v-if="showToolbar" class="contextual-toolbar" :style="toolbarStyle">
      <button @click="showNoteInterface">Add Note</button>
    </div>

    <NoteForm
      v-if="showNoteForm"
      :style="noteFormStyle"
      :selectedText="selectedText"
      :selectionStart="selectionStart"
      :selectionEnd="selectionEnd"
      :availableTags="availableTags"
      @save="addNote"
      @cancel="cancelNote"
    />

    <div class="notes-list">
      <h3>Notes:</h3>
      <ul>
        <li v-for="(note, index) in notes" :key="index" @click="navigateToNote(note)">
          <strong>{{ note.text.substring(0, 50) }}...</strong>
          [{{ note.tags.join(', ') }}]
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import NoteForm from './NoteForm.vue';

export default {
  name: 'HTMLViewer',
  components: {
    NoteForm
  },
  data() {
    return {
      htmlContent: '',
      markedContent: '',
      loading: true,
      error: null,
      showToolbar: false,
      showNoteForm: false,
      toolbarStyle: {
        top: '0px',
        left: '0px',
      },
      noteFormStyle: {
        top: '0px',
        left: '0px',
      },
      selectedText: '',
      selectionStart: 0,
      selectionEnd: 0,
      notes: [],
      availableTags: [],
      loadingTags: true,
      tagError: null,
    };
  },
  mounted() {
    this.fetchHTML();
    this.fetchNotes();
    this.fetchTags();
    document.addEventListener('keydown', this.handleHotkey);
  },
  beforeUnmount() {
    document.removeEventListener('keydown', this.handleHotkey);
  },
  methods: {
    async fetchHTML() {
      try {
        const response = await axios.get(`${import.meta.env.VITE_CRM_API_HOST}/retrieve-html/AoyUugnQbLGteVbxFIL8RwMyC2M2e735HFi4fHCaOw@famaga.org.html`);
        this.htmlContent = response.data;
        this.updateMarkedContent();
        this.loading = false;
      } catch (error) {
        this.error = 'Failed to fetch HTML content';
        this.loading = false;
      }
    },
    async fetchNotes() {
      try {
        const response = await axios.get('http://127.0.0.1:8027/notes/');
        this.notes = response.data;
        this.updateMarkedContent();
      } catch (error) {
        console.error('Failed to fetch notes:', error);
      }
    },
    async fetchTags() {
      try {
        const response = await axios.get('http://127.0.0.1:8027/tags/');
        this.availableTags = response.data;
        this.loadingTags = false;
      } catch (error) {
        console.error('Failed to fetch tags:', error);
        this.tagError = 'Failed to load tags. Please try again.';
        this.loadingTags = false;
      }
    },
    handleSelection() {
      const selection = window.getSelection();
      if (selection.toString().trim().length > 0) {
        this.selectedText = selection.toString().trim();
        const range = selection.getRangeAt(0);
        this.selectionStart = this.getTextOffset(range.startContainer, range.startOffset);
        this.selectionEnd = this.getTextOffset(range.endContainer, range.endOffset);
        const rect = range.getBoundingClientRect();
        this.toolbarStyle = {
          top: `${rect.top - 30 + window.scrollY}px`,
          left: `${rect.left + window.scrollX}px`,
        };
        this.showToolbar = true;
      } else {
        this.showToolbar = false;
      }
    },
    getTextOffset(node, offset) {
      let totalOffset = offset;
      while (node !== this.$refs.content) {
        if (node.previousSibling) {
          node = node.previousSibling;
          totalOffset += node.textContent.length;
        } else {
          node = node.parentNode;
        }
      }
      return totalOffset;
    },
    showNoteInterface() {
      if (this.selectedText) {
        const selection = window.getSelection();
        const range = selection.getRangeAt(0);
        const rect = range.getBoundingClientRect();
        this.noteFormStyle = {
          top: `${rect.bottom + window.scrollY}px`,
          left: `${rect.left + window.scrollX}px`,
        };
        this.showNoteForm = true;
        this.showToolbar = false;
      }
    },
    async addNote(note) {
      try {
        const response = await axios.post('http://127.0.0.1:8027/notes/', {
          text: this.selectedText,
          comment: note.text,
          tags: note.tags,
          selectionStart: note.selectionStart,
          selectionEnd: note.selectionEnd
        });
        this.notes.push(response.data);
        this.cancelNote();
        this.updateMarkedContent();
      } catch (error) {
        console.error('Failed to add note:', error);
      }
    },
    cancelNote() {
      this.showNoteForm = false;
    },
    handleHotkey(event) {
      if (event.metaKey && event.key === 'k') {
        event.preventDefault();
        this.showNoteInterface();
      }
    },
    updateMarkedContent() {
      let markedContent = this.htmlContent;
      this.notes.forEach((note, index) => {
        const marker = `<span class="note-marker" data-note-id="${index}">📝</span>`;
        markedContent = markedContent.slice(0, note.selectionEnd) + marker + markedContent.slice(note.selectionEnd);
      });
      this.markedContent = markedContent;
    },
    navigateToNote(note) {
      const noteElement = this.$refs.content.querySelector(`[data-note-id="${this.notes.indexOf(note)}"]`);
      if (noteElement) {
        noteElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        this.highlightText(note.selectionStart, note.selectionEnd);
      }
    },
    highlightText(start, end) {
      const content = this.$refs.content;
      const range = document.createRange();
      const startNode = this.getNodeAndOffsetFromPosition(content, start);
      const endNode = this.getNodeAndOffsetFromPosition(content, end);
      
      range.setStart(startNode.node, startNode.offset);
      range.setEnd(endNode.node, endNode.offset);

      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);

      setTimeout(() => {
        window.getSelection().removeAllRanges();
      }, 2000);
    },
    getNodeAndOffsetFromPosition(rootNode, targetOffset) {
      const walker = document.createTreeWalker(rootNode, NodeFilter.SHOW_TEXT, null, false);
      let currentOffset = 0;
      let currentNode = walker.nextNode();

      while (currentNode) {
        if (currentOffset + currentNode.length > targetOffset) {
          return { node: currentNode, offset: targetOffset - currentOffset };
        }
        currentOffset += currentNode.length;
        currentNode = walker.nextNode();
      }

      return { node: rootNode, offset: rootNode.childNodes.length };
    }
  }
};
</script>

<style scoped>
.contextual-toolbar {
  position: absolute;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 3px;
  z-index: 1000;
}

.notes-list {
  margin-top: 20px;
}

.note-marker {
  background-color: yellow;
  cursor: pointer;
}

.notes-list ul {
  list-style-type: none;
  padding: 0;
}

.notes-list li {
  cursor: pointer;
  padding: 5px;
  border-bottom: 1px solid #eee;
}

.notes-list li:hover {
  background-color: #f0f0f0;
}
</style>