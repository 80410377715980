<template>
  <div id="app">
    <nav class="navigation">
      <router-link to="/" class="nav-link">Home</router-link>
      <router-link to="/discount" class="nav-link">Discount Decision</router-link>
      <router-link to="/discount-runs" class="nav-link">Discount Runs List</router-link>
    </nav>
    <h1>Trace Viewer</h1>
    <router-view></router-view>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive } from 'vue';
import SpanContent from './components/builder/SpanContent.vue';
import HtmlViewer from './components/HtmlViewer.vue';
// import Some from './components/Some.vue'
import SpanDisplay from './components/builder/SpanDisplay.vue'
import TraceViewer from './components/builder/TraceViewer.vue';
import RecursiveTable from './components/builder/RecursiveTable.vue';
import DiscountDecision from './components/DiscountDecision.vue';
import DiscountRunsList from './components/DiscountRunsList.vue';


export default defineComponent({
  name: 'App',
  components: {
    SpanContent,
    // Some,
    SpanDisplay,
    TraceViewer,
    RecursiveTable,
    HtmlViewer,
    DiscountDecision,
    DiscountRunsList
  },
  setup() {
    const spanContent = reactive({
      event_type: "builder_component",
      operation_type: "reasoning",
      operation_name: "get_decision",
      message: "This is an overall message for the entire component. It can be used to provide context or instructions.",
      style: {
        border: "1px solid #ccc",
        padding: "15px",
        borderRadius: "5px"
      },
      components: [
      {
          type: "ManagerTextField",
          props: {
            label: "Message for manager",
            value: "Based on the customer's purchase history and the current request, we recommend approving a 10% discount. The customer has been a loyal buyer for over 2 years and this discount aligns with our customer retention strategy.",
            scrollable: true,
            maxHeight: "200px"
          },
          style: {
            color: "#004085",
            fontSize: "16px",
            lineHeight: "1.5"
          }
        },
        {
          type: "CustomerInfoTextField",
          props: {
            label: "Customer Information",
            value: "Customer ID: CID12345\nLoyalty Level: Gold\nTotal Purchases: $5,000\nAverage Order Value: $250"
          },
          style: {
            color: "#155724",
            fontSize: "14px",
            lineHeight: "1.5"
          }
        },
        {
          type: "NoteTextField",
          props: {
            label: "Important Note",
            value: "Remember to check the customer's previous interactions before finalizing the decision."
          },
          style: {
            color: "#856404",
            fontSize: "14px",
            lineHeight: "1.5"
          }
        },
        {
          type: "TextField",
          props: {
            label: "Previous Interactions",
            value: "Last interaction: 2 weeks ago\nNature of interaction: Product inquiry\nOutcome: Positive, customer expressed interest in a bulk purchase",
            scrollable: true,
            maxHeight: "150px"
          },
          style: {
            color: "#333",
            fontSize: "14px",
            lineHeight: "1.5"
          }
        },
        {
          type: "TextField",
          props: {
            label: "Discount History",
            value: "Previous discounts:\n- 5% off on order #10234 (3 months ago)\n- Free shipping on order #9876 (6 months ago)",
            scrollable: true,
            maxHeight: "150px"
          },
          style: {
            color: "#333",
            fontSize: "14px",
            lineHeight: "1.5"
          }
        },
        {
          type: "TextField",
          props: {
            label: "Related Documents",
            value: "Loyalty Program Guidelines: https://example.com/loyalty-program\nDiscount Approval Process: https://example.com/discount-policy"
          },
          style: {
            color: "#333",
            fontSize: "14px",
            lineHeight: "1.5"
          }
        },
        {
          type: "TextField",
          props: {
            label: "Message for manager",
            value: "Based on the customer's purchase history and the current request, we recommend approving a 10% discount. The customer has been a loyal buyer for over 2 years and this discount aligns with our customer retention strategy.",
            scrollable: true,
            maxHeight: "200px"
          },
          style: {
            color: "#333",
            fontSize: "14px",
            lineHeight: "1.5"
          }
        },
        {
          type: "Attributes",
          props: {
            label: "Customer Information",
            attributes: {
              "Customer ID": "CID12345",
              "Loyalty Level": "Gold",
              "Total Purchases": "$5,000",
              "Average Order Value": "$250"
            }
          }
        },
        {
          type: "Note",
          props: {
            label: "Important Note",
            value: "Remember to check the customer's previous interactions before finalizing the decision."
          }
        },
        {
          type: "References",
          props: {
            label: "Related Documents",
            references: [
              { link: "https://example.com/loyalty-program", name: "Loyalty Program Guidelines" },
              { link: "https://example.com/discount-policy", name: "Discount Approval Process" }
            ]
          }
        }
      ]
    });

    const customStyle = {
      headerColor: '#b8daff',
      borderColor: '#b8daff'
    };

    const tableData = {
      type: "Table",
      props: {
        headers: ["Request ID", "Firm ID", "Decision", "Created"],
        rows: [
          {
            cells: [
              { value: "1000" },
              { value: "42" },
              { value: "Approved" },
              { value: "2023-08-01 15:30:45" }
            ],
            subtable: {
              type: "Table",
              props: {
                headers: ["ID", "Articul", "Brand ID", "Count", "Checkout Price", "Discount", "Margin"],
                rows: [
                  {
                    cells: [
                      { value: "0" },
                      { value: "PROD-0-0" },
                      { value: "3" },
                      { value: "15" },
                      { value: "275.5" },
                      { value: "7.5" },
                      { value: "25.0" }
                    ],
                    subtable: {
                      type: "Table",
                      props: {
                        headers: ["Articul", "Checkout Price", "Count", "Discount", "Margin Ratio", "Tax", "Unit"],
                        rows: [
                          {
                            cells: [
                              { value: "ART-0-0-0" },
                              { value: "55.75" },
                              { value: "5" },
                              { value: "10.0" },
                              { value: "17.5" },
                              { value: "10" },
                              { value: "pcs" }
                            ]
                          }
                        ]
                      }
                    }
                  }
                ]
              }
            }
          }
        ]
      }
    };

    return {
      spanContent,
      customStyle,
      tableData
    };
  }
});

// Error handling
window.onerror = function(message, source, lineno, colno, error) {
  console.log('Full error object:', error);
  console.log('Stack trace:', error?.stack);
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 60px;
}

.navigation {
  background-color: #f8f9fa;
  padding: 1rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0,0,0,.1);
}

.nav-link {
  color: #495057;
  text-decoration: none;
  padding: 0.5rem 1rem;
  margin: 0 0.5rem;
  border-radius: 0.25rem;
  transition: background-color 0.3s ease;
}

.nav-link:hover {
  background-color: #e9ecef;
}

.router-link-active {
  font-weight: bold;
  color: #007bff;
}
</style>