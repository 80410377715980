// trace-utils.ts

// trace-utils.ts
import type { ParsedLog, Component, ParsedSpan } from './trace-interfaces';

// export function parseEventWithUIComponents(
//   log: ParsedLog,
//   eventTypeField: string,
//   eventTypeValue: string,
//   uiComponentsField: string
// ): Component[] | null {
//   console.log(`Parsing event with type ${eventTypeValue}`);
//   const eventTypeTag = log.fields.find(field => field.key === eventTypeField);
//   if (eventTypeTag && eventTypeTag.value === eventTypeValue) {
//     const uiComponentsTag = log.fields.find(field => field.key === uiComponentsField);
//     if (uiComponentsTag) {
//       try {
//         const parsedComponents = JSON.parse(uiComponentsTag.value);
//         if (parsedComponents && parsedComponents.components) {
//           console.log('Parsed UI components:', parsedComponents.components);
//           return parsedComponents.components;
//         }
//       } catch (error) {
//         console.error('Error parsing UI components:', error);
//       }
//     }
//   }
//   console.log('No matching UI components found');
//   return null;
// }

export function parseAllEventsWithUIComponents(
  spans: ParsedSpan[],
  eventTypeField: string,
  eventTypeValue: string,
  uiComponentsField: string
): Component[] {
  console.log('Parsing all events with UI components');
  // const allComponents: Component[] = [];

  // spans.forEach(span => {
  //   span.logs.forEach(log => {
  //     const components = parseEventWithUIComponents(log, eventTypeField, eventTypeValue, uiComponentsField);
  //     if (components) {
  //       allComponents.push(...components);
  //     }
  //   });
  // });

  // console.log('Total UI components found:', allComponents.length);
  // return allComponents;
  return []
}