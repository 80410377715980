<template>
  <div class="trace-viewer">
    <div v-if="isLoading">Loading trace data...</div>
    <div v-else-if="error">Error: {{ error }}</div>
    <template v-else-if="trace">
      <div class="span-tree">
        <SpanComponent
          :span="trace"
        />
      </div>
    </template>
    <div v-else>No trace data available.</div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, watch } from 'vue';
import SpanComponent from './SpanComponent.vue';
import { TraceProvider } from '@/otel/trace-provider';
import { TraceBuilder } from '@/otel/trace-builder';
import type { Span } from '@/types/pipeline-models';

const props = defineProps<{
  traceId: string
}>();

// const traceProvider = new TraceProvider('http://localhost:8089');
const traceProvider = new TraceProvider('https://crm.neon-dev.us');
const trace = ref<Span | null>(null);
const isLoading = ref(true);
const error = ref<string | null>(null);

const fetchTrace = async (traceId: string) => {
  isLoading.value = true;
  error.value = null;
  try {
    console.log(`Start reading trace ${traceId}`);
    const traceData = await traceProvider.getTrace(traceId);
    trace.value = new TraceBuilder().buildHierarchy(traceData);
    console.log(trace.value);
  } catch (err) {
    error.value = err instanceof Error ? err.message : 'An unknown error occurred';
    console.error('Error fetching trace:', err);
  } finally {
    isLoading.value = false;
  }
};

onMounted(() => {
  fetchTrace(props.traceId);
});

watch(() => props.traceId, (newTraceId) => {
  fetchTrace(newTraceId);
});
</script>

<style scoped>
.trace-viewer {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.span-tree {
  margin-left: 20px;
}

.span-tree > * {
  margin-bottom: 10px;
}
</style>
