import { ref, onMounted } from 'vue';
import { TraceProvider } from '@/otel/trace-provider';
import type { ParsedTrace, Component } from './trace-interfaces';
import { parseAllEventsWithUIComponents } from './trace-utils';

export function useTrace(traceId: string) {
  const trace = ref<ParsedTrace | null>(null);
  const uiComponents = ref<Component[]>([]);
  const error = ref<string | null>(null);
  const loading = ref(false);

  const jaegerBaseUrl = 'http://localhost:8089'; // Replace with your Jaeger URL
  const traceProvider = new TraceProvider(jaegerBaseUrl);

  const fetchTrace = async () => {
    loading.value = true;
    error.value = null;
    try {
      const parsedTrace = await traceProvider.getTypedTrace(traceId);
      trace.value = parsedTrace;

      const components = parseAllEventsWithUIComponents(
        parsedTrace.spans,
        'message',
        'discount_approval_ui',
        'ui_components'
      );
      uiComponents.value = components;
    } catch (e) {
      console.error('Error fetching trace:', e);
      error.value = 'Failed to fetch trace data';
    } finally {
      loading.value = false;
    }
  };

  onMounted(fetchTrace);

  return {
    trace,
    uiComponents,
    error,
    loading,
    refetch: fetchTrace,
  };
}