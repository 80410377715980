import type { JaegerTrace, JaegerSpan } from './trace-interfaces';
import type { Span, SpanEvent, Component } from '@/types/pipeline-models';

export class TraceBuilder {
  private spanMap: Map<string, Span> = new Map();

  buildHierarchy(jaegerTrace: JaegerTrace): Span | null {
    this.spanMap.clear();
    const parsedSpans = this.parseSpans(jaegerTrace.spans);
    console.log('Parsed spans:', parsedSpans);

    // Create a map of decision pipeline spans
    parsedSpans.forEach(span => {
      if (span) {
        this.spanMap.set(span.spanId, span);
      }
    });

    // Build the hierarchy for decision pipeline spans
    let rootSpan: Span | null = null;
    this.spanMap.forEach(span => {
      if (span.parentTraceId) {
        const parentSpan = this.spanMap.get(span.parentTraceId);
        if (parentSpan) {
          parentSpan.childSpans.push(span);
        } else if (!rootSpan) {
          // If parent is not in the map, this might be the root decision pipeline span
          rootSpan = span;
        }
      } else if (!rootSpan) {
        // If no parent, this is the root decision pipeline span
        rootSpan = span;
      }
    });

    return rootSpan;
  }

  private parseSpans(jaegerSpans: JaegerSpan[]): (Span | null)[] {
    return jaegerSpans.map(span => {
      const spanType = span.tags.find(tag => tag.key === 'span_type')?.value;
      if (spanType !== 'decision_pipeline') {
        return null;
      }

      const description = span.tags.find(tag => tag.key === 'description')?.value as string || '';
      const events = this.parseEvents(span.logs);
      
      return {
        parentTraceId: this.findParentSpanID(span),
        spanId: span.spanID,
        message: description,
        created: new Date(span.startTime / 1000), // Convert microseconds to milliseconds
        duration_ms: span.duration / 1000, // Convert microseconds to milliseconds
        attributes: Object.fromEntries(span.tags.map(tag => [tag.key, tag.value])),
        childSpans: [],
        events: events
      };
    });
  }

  private parseEvents(logs: JaegerSpan['logs']): SpanEvent[] {
    return logs.map(log => {
      const fields = Object.fromEntries(log.fields.map(field => [field.key, field.value]));
      const components = this.parseUIComponents(fields.ui_components);
      const styles = this.parseStyles(fields.styles);
      
      return {
        event_type: fields.event_type || 'unknown',
        operation_type: fields.operation_type || 'unknown',
        operation_name: fields.operation_name || 'unknown',
        message: fields.message || '',
        components: components,
        style: styles,
        eventCustomStyle: {
          borderColor: styles?.borderColor,
          headerColor: styles?.headerColor
        }
      };
    });
  }

  private parseUIComponents(uiComponentsString: string | undefined): Component[] {
    if (!uiComponentsString) {
      return [];
    }
    try {
      const parsed = JSON.parse(uiComponentsString);
      return Array.isArray(parsed.components) ? parsed.components : [];
    } catch (error) {
      console.error('Error parsing UI components:', error);
      return [];
    }
  }

  private parseStyles(stylesString: string | undefined): Record<string, string> | undefined {
    if (!stylesString) {
      return undefined;
    }
    try {
      return JSON.parse(stylesString);
    } catch (error) {
      console.error('Error parsing styles:', error);
      return undefined;
    }
  }

  private findParentSpanID(span: JaegerSpan): string | undefined {
    const parentReference = span.references.find(ref => ref.refType === 'CHILD_OF');
    return parentReference ? parentReference.spanID : undefined;
  }
}