<template>
  <div class="discount-decision">
    <h2>Discount Decision</h2>
    <form @submit.prevent="submitRequest">
      <div>
        <label for="request_id">Request ID:</label>
        <input id="request_id" v-model="requestData.request_id" type="number" required>
      </div>
      <div>
        <label for="is_large_company">Is Large Company:</label>
        <input id="is_large_company" v-model="requestData.is_large_company" type="checkbox">
      </div>
      <button type="submit">Submit</button>
    </form>
    <div v-if="isLoading">Loading...</div>
    <div v-else-if="error">Error: {{ error }}</div>
    <div v-if="runId" class="feedback-section">
      <button @click="openFeedbackModal('like')">Leave Positive Feedback</button>
      <button @click="openFeedbackModal('dislike')">Report an Issue</button>
    </div>
    <FeedbackModal
      :show="showFeedbackModal"
      :type="feedbackType"
      :runId="runId"
      :dealId="requestData.request_id.toString()"
      :traceId="traceId"
      @close="closeFeedbackModal"
      @submit="submitFeedback"
    />
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import axios from 'axios';
import FeedbackModal from './FeedbackModal.vue';

const router = useRouter();

const requestData = ref({
  request_id: 0,
  is_large_company: false
});

const isLoading = ref(false);
const error = ref<string | null>(null);
const runId = ref<number | null>(null);
const traceId = ref<string | null>(null);

const showFeedbackModal = ref(false);
const feedbackType = ref('');

interface DecisionResponse {
  decisions: any[];
  metadata: {
    pipeline_id: string;
    run_id: number;
  };
  bot: {
    msg: string;
    customer_msg: string;
    hyperlinks: Array<{ text: string; link: string }>;
  };
}

const submitRequest = async () => {
  isLoading.value = true;
  error.value = null;
  try {
    console.log('Request body:', requestData.value);
    const result = await axios.post<DecisionResponse>(`${import.meta.env.VITE_APP_API_HOST}/v3/discount/decision`, requestData.value);
    runId.value = result.data.metadata.run_id;
    traceId.value = result.data.metadata.pipeline_id;
    if (runId.value) {
      router.push({
        name: 'DecisionResult',
        params: { runId: runId.value.toString() }
      });
    } else {
      throw new Error('No run ID received from the server');
    }
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      console.error('Server responded with:', err.response.status, err.response.data);
      error.value = `Server error: ${err.response.status}`;
    } else {
      error.value = err instanceof Error ? err.message : 'An unknown error occurred';
    }
  } finally {
    isLoading.value = false;
  }
};

const openFeedbackModal = (type: string) => {
  feedbackType.value = type;
  showFeedbackModal.value = true;
};

const closeFeedbackModal = () => {
  showFeedbackModal.value = false;
};

const submitFeedback = (feedbackData: any) => {
  console.log('Feedback submitted:', feedbackData);
  closeFeedbackModal();
};
</script>

<style scoped>
.discount-decision {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.feedback-section {
  margin-top: 20px;
}

.feedback-section button {
  margin-right: 10px;
}
</style>
