<template>
  <div v-if="show" class="modal" @click.self="close">
    <div class="modal-content">
      <h3>{{ title }}</h3>
      <div class="span-info">
        <span class="span-id">Run ID: {{ runId }}</span>
        <span class="span-operation">Deal ID: {{ dealId }}</span>
      </div>
      <textarea v-model="comment" placeholder="Enter your comment here..."></textarea>
      <div v-if="type === 'dislike'">
        <h4>Common Issues:</h4>
        <div class="common-issues">
          <div v-for="issue in commonIssues" :key="issue.issue_id" class="issue-item">
            <input type="checkbox" :id="'issue-' + issue.issue_id" v-model="selectedIssues[issue.issue_id]">
            <label :for="'issue-' + issue.issue_id">{{ issue.description }}</label>
          </div>
        </div>
      </div>
      <div class="modal-buttons">
        <button @click="submit">Submit</button>
        <button @click="close">Cancel</button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import axios from 'axios';

export default {
  name: 'FeedbackModal',
  props: {
    show: Boolean,
    type: String,
    runId: {
      type: Number,
      required: true
    },
    dealId: {
      type: Number,
      required: true
    },
    traceId: {
      type: String,
      required: true
    }
  },
  emits: ['close', 'submit'],
  setup(props, { emit }) {
    const comment = ref('');
    const commonIssues = ref([]);
    const selectedIssues = ref({});

    const title = computed(() => props.type === 'dislike' ? 'Report an Issue' : 'Leave a Comment');

    const fetchCommonIssues = async () => {
      try {
        const response = await axios.get(`${import.meta.env.VITE_CRM_API_HOST}/issues/by-group/client_request_classification/`, {
          headers: {
            'X-CSRFToken': 'T7mM9qBuHzvj3kzN4WEQHKzBKCRpDcsJTDRuTX5KqwK8X8BxZ73sTAZPUrhgAdJS'
          }
        });
        commonIssues.value = response.data;
        commonIssues.value.forEach(issue => {
          selectedIssues.value[issue.issue_id] = false;
        });
      } catch (error) {
        console.error('Error fetching common issues:', error);
      }
    };

    const close = () => {
      emit('close');
    };

    const submit = async () => {
      const selectedIssueIds = Object.entries(selectedIssues.value)
        .filter(([_, value]) => value)
        .map(([key, _]) => parseInt(key));

      const feedbackData = {
        run: props.runId,
        feedback: comment.value,
        is_like: props.type === 'like' ? 1 : 0,
        trace_id: props.traceId,
        deal_id: props.dealId,
        issues: selectedIssueIds
      };

      try {
        await axios.post(`${import.meta.env.VITE_CRM_API_HOST}/llm-run-feedback/`, feedbackData, {
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': 'T7mM9qBuHzvj3kzN4WEQHKzBKCRpDcsJTDRuTX5KqwK8X8BxZ73sTAZPUrhgAdJS'
          }
        });
        emit('submit', feedbackData);
        comment.value = '';
        Object.keys(selectedIssues.value).forEach(key => {
          selectedIssues.value[key] = false;
        });
      } catch (error) {
        console.error('Error submitting feedback:', error);
      }
    };

    onMounted(() => {
      fetchCommonIssues();
    });

    return {
      comment,
      commonIssues,
      selectedIssues,
      title,
      close,
      submit
    };
  }
}
</script>

<style scoped>
.modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  width: 80%;
  max-width: 500px;
}

.span-info {
  font-size: 0.8em;
  color: #666;
  margin-bottom: 10px;
}

.span-id, .span-operation {
  display: block;
  margin-bottom: 5px;
}

textarea {
  width: 100%;
  height: 100px;
  margin-bottom: 10px;
  padding: 5px;
}

.common-issues {
  text-align: left;
  margin-bottom: 10px;
}

.issue-item {
  margin-bottom: 5px;
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
}

button {
  margin-left: 10px;
  padding: 5px 10px;
  cursor: pointer;
}
</style>