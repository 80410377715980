<template>
    <div class="feedback-list">
      <h3>Feedback</h3>
      <div v-if="isLoading">Loading feedback...</div>
      <div v-else-if="error">Error loading feedback: {{ error }}</div>
      <div v-else-if="feedbacks.length === 0">No feedback available for this run.</div>
      <div v-else>
        <div v-for="feedback in feedbacks" :key="feedback.uuid" class="feedback-item">
          <div class="feedback-header">
            <span :class="['feedback-type', feedback.is_like ? 'positive' : 'negative']">
              {{ feedback.is_like ? 'Positive' : 'Negative' }}
            </span>
          </div>
          <div v-if="feedback.feedback" class="feedback-comment">
            {{ feedback.feedback }}
          </div>
          <div v-if="feedback.issues.length > 0" class="feedback-issues">
            <ul>
              <li v-for="issue in feedback.issues" :key="issue.issue_id">
                {{ issue.description }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script lang="ts" setup>
  import { ref, onMounted, watch } from 'vue';
  import axios from 'axios';
  
  const props = defineProps<{
    runId: number
  }>();
  
  const feedbacks = ref([]);
  const isLoading = ref(false);
  const error = ref(null);

  
  const fetchFeedback = async (runId: number) => {
    isLoading.value = true;
    error.value = null;
    try {
      const crmApiHost = import.meta.env.VITE_CRM_API_HOST as string;
      console.log(crmApiHost);
      const response = await axios.get(`${crmApiHost}/llm-runs/${runId}/feedbacks/`, {
        headers: {
          'X-CSRFToken': 'qpWxB7JWfWasRTJVf5sRuRbJl8eFvt0LqVrflEdcYTphLHLFagRtGHBXvXEwsuhU'
        }
      });
      feedbacks.value = response.data;
    } catch (err) {
      error.value = err instanceof Error ? err.message : 'An unknown error occurred';
    } finally {
      isLoading.value = false;
    }
  };
  
  onMounted(() => {
    if (props.runId) {
      fetchFeedback(props.runId);
    }
  });
  
  watch(() => props.runId, (newRunId) => {
    if (newRunId) {
      fetchFeedback(newRunId);
    }
  });
  </script>
  
  <style scoped>
  .feedback-list {
    margin-top: 20px;
    background-color: #f5f5f5;
    padding: 15px;
    border-radius: 5px;
  }
  
  .feedback-item {
    background-color: #fffbea;
    border: 1px solid #ffeeba;
    padding: 12px;
    margin-bottom: 12px;
    border-radius: 4px;
  }
  
  .feedback-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
  }
  
  .feedback-type {
    font-weight: bold;
    padding: 2px 6px;
    border-radius: 3px;
  }
  
  .positive {
    background-color: #d4edda;
    color: #155724;
  }
  
  .negative {
    background-color: #f8d7da;
    color: #721c24;
  }
  
  .feedback-comment {
    margin-bottom: 8px;
    font-style: italic;
  }
  
  .feedback-issues ul {
    margin: 0;
    padding-left: 20px;
  }
  
  .feedback-issues li {
    margin-bottom: 4px;
  }
  </style>