<template>
  <div class="text-field-component" :style="componentStyle">
    <label v-if="label" class="text-field-label" :style="labelStyle">{{ label }}</label>
    <div
      class="text-field-content"
      :class="{ scrollable: isScrollable }"
      :style="contentStyle"
    >
      {{ value }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

export interface TextFieldProps {
  label?: string;
  value?: string;
}

export interface TextFieldStyleProps {
  maxHeight?: string;
  borderColor?: string;
  fontSize?: string;
  fontFamily?: string;
  textColor?: string;
  labelColor?: string;
}

const props = withDefaults(defineProps<TextFieldProps & TextFieldStyleProps>(), {
  label: undefined,
  value: '',
  maxHeight: '300px',
  borderColor: '#d8b5ff',
  fontSize: '14px',
  fontFamily: 'Arial, sans-serif',
  textColor: '#333',
  labelColor: '#271038',
});

const componentStyle = computed(() => ({
  fontFamily: props.fontFamily,
  fontSize: props.fontSize,
  color: props.textColor,
}));

const labelStyle = computed(() => ({
  color: props.labelColor,
}));

const isScrollable = computed(() => {
  return (props.value?.length ?? 0) > 100; // This is an approximation. Adjust as needed.
});

const contentStyle = computed(() => ({
  maxHeight: props.maxHeight,
  overflowY: isScrollable.value ? 'auto' : 'visible',
  borderColor: props.borderColor,
}));
</script>

<style scoped>
.text-field-component {
  line-height: 1.4;
  max-width: 800px;
  margin: 0 auto 10px;
  text-align: left;
}

.text-field-label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.text-field-content {
  background-color: #ffffff;
  border: 1px solid;
  border-radius: 4px;
  padding: 10px;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.text-field-content.scrollable {
  overflow-y: auto;
}
</style>