<template>
  <div class="discount-runs-list">
    <h2>Discount Runs</h2>
    <div v-if="isLoading">Loading...</div>
    <div v-else-if="error">Error: {{ error }}</div>
    <div v-else-if="runs && runs.length === 0">No discount runs available.</div>
    <div v-else-if="runs">
      <table>
        <thead>
          <tr>
            <th>Run ID</th>
            <th>Status</th>
            <th>Run Type</th>
            <th>Deal ID</th>
            <th>Created At</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="run in runs" :key="run.run_id">
            <td>{{ run.run_id }}</td>
            <td>{{ getStatusText(run.status) }}</td>
            <td>{{ run.run_type }}</td>
            <td>{{ run.deal_id }}</td>
            <td>{{ new Date(run.created_at).toLocaleString() }}</td>
            <td>
              <button @click="viewDetails(run)">View Details</button>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="pagination">
        <button :disabled="!previous" @click="changePage(previous)">Previous</button>
        <span>Page {{ currentPage }} of {{ totalPages }}</span>
        <button :disabled="!next" @click="changePage(next)">Next</button>
      </div>
    </div>

    <div v-if="selectedRun" class="run-details">
      <h3>Run Details</h3>
      <pre>{{ JSON.stringify(selectedRun.data, null, 2) }}</pre>
      <button @click="selectedRun = null">Close</button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, computed } from 'vue';
import { useRouter } from 'vue-router';
import axios from 'axios';

interface Run {
  run_id: number;
  uuid: string;
  status: number;
  run_type: string;
  origin: string;
  created_at: string;
  updated_at: string;
  deal_id: string;
  trace_id: string;
  data: string;
}

const router = useRouter();

const runs = ref<Run[] | null>(null);
const isLoading = ref(true);
const error = ref<string | null>(null);
const next = ref<string | null>(null);
const previous = ref<string | null>(null);
const count = ref(0);
const currentPage = ref(1);
const pageSize = 10;
const selectedRun = ref<Run | null>(null);

const totalPages = computed(() => Math.ceil(count.value / pageSize));

const fetchRuns = async (url = `${import.meta.env.VITE_CRM_API_HOST}/llm-runs/`) => {
  isLoading.value = true;
  error.value = null;
  try {
    const response = await axios.get<{
      results: Run[];
      next: string | null;
      previous: string | null;
      count: number;
    }>(url, {
      params: { page_size: pageSize },
      headers: {
        'X-CSRFToken': '0sB3h5DgT6ZZVUf11Jt0VmvTJ2mpxcxo0Y6L1C7wC3eOPIhLWUSC7cV7TRMgudOx'
      }
    });
    runs.value = response.data.results;
    next.value = response.data.next;
    previous.value = response.data.previous;
    count.value = response.data.count;
    currentPage.value = parseInt(new URL(url).searchParams.get('page') || '1', 10);

    // Log whether response returned an array of items
    console.log('Response returned an array of items:', Array.isArray(response.data.results));

    console.log(runs.value)

  } catch (err) {
    console.log(err)
    error.value = err instanceof Error ? err.message : 'An unknown error occurred';
    runs.value = null;
  } finally {
    isLoading.value = false;
  }
};

const changePage = (url: string | null) => {
  if (url) {
    fetchRuns(url);
  }
};

const getStatusText = (status: number): string => {
  const statusMap: { [key: number]: string } = {
    1: 'Pending',
    2: 'In Progress',
    3: 'Failed',
    4: 'Completed'
  };
  return statusMap[status] || 'Unknown';
};

const viewDetails = (run: Run) => {
  router.push({ name: 'DecisionResult', params: { runId: run.run_id.toString() } });
};

onMounted(() => {
  fetchRuns();
});
</script>

<style scoped>
.discount-runs-list {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.run-details {
  margin-top: 20px;
  border: 1px solid #ddd;
  padding: 20px;
}

pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}
</style>
