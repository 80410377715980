<!-- RecursiveTable.vue -->
<template>
  <div class="recursive-table" v-if="table">
    <table class="table table-bordered table-hover">
      <thead v-if="table.columns" class="table-header">
        <tr>
          <th v-for="(column, index) in table.columns" :key="index">{{ column }}</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(row, rowIndex) in table.rows" :key="rowIndex">
          <tr @click="toggleRow(rowIndex)" :class="{ 'expandable': row.nested_table }">
            <td v-for="(column, columnIndex) in table.columns" :key="columnIndex">
              {{ row.cells && row.cells[column] ? row.cells[column].value : '' }}
            </td>
          </tr>
          <tr v-if="row.nested_table && expandedRows[rowIndex]" class="nested-table-row">
            <td :colspan="table.columns.length">
              <RecursiveTable :table="row.nested_table" class="nested-table" />
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts" setup>
import { defineProps, ref, onMounted } from 'vue';

interface TableCell {
  value: any;
}

interface TableRow {
  cells: Record<string, TableCell>;
  nested_table?: TableData;
}

interface TableData {
  columns: string[];
  rows: TableRow[];
}

interface RecursiveTableProps {
  table: TableData;
}

const props = defineProps<RecursiveTableProps>();
const expandedRows = ref<Record<number, boolean>>({});

const toggleRow = (rowIndex: number) => {
  if (props.table.rows[rowIndex].nested_table) {
    expandedRows.value[rowIndex] = !expandedRows.value[rowIndex];
  }
};

onMounted(() => {
  if (props.table) {
    console.log('RecursiveTable component initialized with props:', JSON.stringify(props, null, 2));
  } else {
    console.warn('RecursiveTable component initialized without table prop');
  }
});
</script>

<style scoped>
.recursive-table {
  width: 100%;
  margin-bottom: 1em;
  font-size: 12px;
}

.table {
  width: 100%;
  table-layout: fixed;
}

.table-header {
  background-color: #f8f9fa;
  color: #343a40;
  font-weight: bold;
}

.table-header th {
  border: 1px solid #dee2e6;
  padding: 8px;
}

tbody tr:nth-child(4n+1),
tbody tr:nth-child(4n+2) {
  background-color: #ffffff;
}

tbody tr:nth-child(4n+3),
tbody tr:nth-child(4n+4) {
  background-color: #f8f9fa;
}

tbody tr:hover {
  background-color: #e9ecef;
}

tbody td {
  padding: 8px;
  border: 1px solid #dee2e6;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.expandable {
  cursor: pointer;
}

.nested-table-row > td {
  padding: 0;
}

.nested-table {
  margin: 0;
  border: 2px solid #007bff;
}

.nested-table .table-header {
  background-color: #cce5ff;
}

.nested-table tbody tr:nth-child(4n+1),
.nested-table tbody tr:nth-child(4n+2) {
  background-color: #e6f2ff;
}

.nested-table tbody tr:nth-child(4n+3),
.nested-table tbody tr:nth-child(4n+4) {
  background-color: #f0f8ff;
}

.nested-table tbody tr:hover {
  background-color: #d4e9ff;
}
</style>