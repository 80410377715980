<template>
  <div class="attributes-component">
    <h5 v-if="label" class="attributes-label">{{ label }}</h5>
    <div class="log-attributes">
      <div v-for="(value, key, index) in attributes" :key="key" class="attribute" :class="{ 'odd-row': index % 2 !== 0 }">
        <div class="attribute-row">
          <span class="attribute-key">{{ key }}</span>
          <div class="attribute-value">
            <template v-if="isSimpleValue(value)">
              {{ formatSimpleValue(value) }}
            </template>
            <div v-else class="complex-value" @click="toggleExpand(key)">
              <div v-if="!isExpanded(key)" class="preview">
                {{ getPreview(value) }}
                <span class="expand-icon">▶</span>
              </div>
              <div v-else>
                <pre><code v-html="highlightSyntax(value)"></code></pre>
                <span class="collapse-icon">▼</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { reactive, onMounted } from 'vue';
import hljs from 'highlight.js/lib/core';
import javascript from 'highlight.js/lib/languages/javascript';

hljs.registerLanguage('javascript', javascript);

export interface AttributesProps {
  label?: string;
  attributes: Record<string, any>;
}

const props = withDefaults(defineProps<AttributesProps>(), {
  label: undefined,
  attributes: () => ({}),
});

const expandedKeys = reactive<Record<string, boolean>>({});

const isSimpleValue = (value: any): boolean => {
  return typeof value !== 'object' || value === null;
};

const formatSimpleValue = (value: any): string => {
  return typeof value === 'string' ? value : String(value);
};

const jsonToJs = (obj: any): any => {
  return JSON.parse(JSON.stringify(obj), (key, value) => {
    if (typeof value === 'string') {
      // Remove quotes from string values
      return value.replace(/^"(.*)"$/, '$1');
    }
    return value;
  });
};

const getPreview = (value: any): string => {
  const formatted = formatValue(value, false);
  if (formatted.length <= 30) return formatted;
  
  const firstKey = Object.keys(value)[0];
  const firstValue = value[firstKey];
  const preview = `{ ${firstKey}: ${formatSimpleValue(firstValue)}`;
  
  return preview.length < 27 ? `${preview}, ...}` : `${preview.slice(0, 24)}...}`;
};

const formatValue = (value: any, pretty = true, indent = 0): string => {
  const space = ' '.repeat(indent);
  const jsValue = jsonToJs(value);
  
  if (Array.isArray(jsValue)) {
    if (!pretty) {
      return `[${jsValue.map(item => formatValue(item, false)).join(', ')}]`;
    }
    const items = jsValue.map(item => `${space}  ${formatValue(item, true, indent + 2)}`).join(',\n');
    return `[\n${items}\n${space}]`;
  } else if (typeof jsValue === 'object' && jsValue !== null) {
    if (!pretty) {
      const entries = Object.entries(jsValue).map(([k, v]) => `${k}: ${formatValue(v, false)}`);
      return `{ ${entries.join(', ')} }`;
    }
    const entries = Object.entries(jsValue)
      .map(([k, v]) => `${space}  ${k}: ${formatValue(v, true, indent + 2)}`)
      .join(',\n');
    return `{\n${entries}\n${space}}`;
  }
  return formatSimpleValue(jsValue);
};

const highlightSyntax = (value: any): string => {
  const formatted = formatValue(value, true);
  return hljs.highlight(formatted, { language: 'javascript' }).value;
};

const toggleExpand = (key: string): void => {
  expandedKeys[key] = !expandedKeys[key];
};

const isExpanded = (key: string): boolean => {
  return !!expandedKeys[key];
};

onMounted(() => {
  hljs.highlightAll();
});
</script>

<style>
@import 'highlight.js/styles/github.css';
</style>

<style scoped>
.attributes-component {
  font-family: Arial, sans-serif;
  font-size: 14px;
  line-height: 1.4;
  color: #333;
  max-width: 800px;
  margin: 0 auto 10px;
  text-align: left;
}

.attributes-label {
  font-size: 14px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.log-attributes {
  font-family: monospace;
  font-size: 14px;
  width: 100%;
  text-align: left;
}

.attribute {
  padding: 4px 0;
}

.odd-row {
  background-color: #f8f8f8;
}

.attribute-row {
  display: flex;
  align-items: flex-start;
  width: 100%;
}

.attribute-key {
  min-width: 150px;
  padding-right: 10px;
  color: #666;
  font-weight: bold;
}

.attribute-value {
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.complex-value {
  width: 100%;
  cursor: pointer;
}

.preview {
  padding: 2px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.expand-icon, .collapse-icon {
  font-size: 12px;
  margin-left: 8px;
}

pre {
  margin: 0;
  padding: 4px 0;
  white-space: pre-wrap;
  word-wrap: break-word;
  max-width: 100%;
  overflow-x: auto;
}

code {
  display: block;
}
</style>
