// trace-provider.ts

import axios, { type AxiosInstance } from 'axios';
import type { JaegerResponse, JaegerTrace } from './trace-interfaces';

export class TraceProvider {
  private axiosInstance: AxiosInstance;

  constructor(baseURL: string) {
    this.axiosInstance = axios.create({
      baseURL,
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    });
  }

  async getTrace(traceId: string): Promise<JaegerTrace> {
    try {
      const response = await this.axiosInstance.get<JaegerResponse>(`/api/traces/${traceId}`);
      if (!response.data.data || response.data.data.length === 0) {
        throw new Error('No trace data found');
      }
      return response.data.data[0];
    } catch (error) {
      console.error('Error fetching trace:', error);
      throw error;
    }
  }
}
