<template>
  <div class="decision-result">
    <h2>Decision Result</h2>
    <div v-if="isLoading">Loading...</div>
    <div v-else-if="error">Error: {{ error }}</div>
    <div v-else-if="runData" class="run-data">
      <h3>Run Details</h3>
      <p><strong>Run ID:</strong> {{ runData.run_id }}</p>
      <p><strong>Deal ID:</strong> {{ runData.deal_id }}</p>
      <p><strong>Name:</strong> {{ runData.name }}</p>
      <p><strong>Status:</strong> {{ runData.status }}</p>
      <p><strong>Start Time:</strong> {{ new Date(runData.start_time).toLocaleString() }}</p>
      <p><strong>End Time:</strong> {{ new Date(runData.end_time).toLocaleString() }}</p>
      <h4>Decision Data:</h4>
      <div v-if="decisionData" class="decision-data">
        <div v-if="decisionData.decisions && decisionData.decisions.length > 0">
          <div v-for="(decision, index) in decisionData.decisions" :key="index" class="decision">
            <!-- Add decision details here if available -->
          </div>
        </div>
        <div v-else>
          <p>No decisions available.</p>
        </div>
        <div v-if="decisionData.bot" class="bot-message">
          <h4>Bot Message:</h4>
          <p>{{ decisionData.bot.msg }}</p>
          <h4>Customer Message:</h4>
          <p v-if="decisionData.bot.customer_msg" style="white-space: pre-line">{{ decisionData.bot.customer_msg }}</p>
          <p v-else>No customer message available.</p>
          <h4>Hyperlinks:</h4>
          <ul v-if="decisionData.bot.hyperlinks && decisionData.bot.hyperlinks.length > 0">
            <li v-for="(link, index) in decisionData.bot.hyperlinks" :key="index">
              <a :href="link.link" target="_blank">{{ link.text }}</a>
            </li>
          </ul>
          <p v-else>No hyperlinks available.</p>
        </div>
      </div>
      <FeedbackList :runId="runData.run_id" />
      <div class="feedback-section">
        <button @click="openFeedbackModal('like')">Leave Positive Feedback</button>
        <button @click="openFeedbackModal('dislike')">Report an Issue</button>
      </div>
    </div>
    <FeedbackModal
      :show="showFeedbackModal"
      :type="feedbackType"
      :runId="runData?.run_id?.toString()"
      :dealId="runData?.deal_id"
      :traceId="decisionData?.metadata?.pipeline_id || null"
      @close="closeFeedbackModal"
      @submit="submitFeedback"
    />
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, watch } from 'vue';
import { useRoute } from 'vue-router';
import axios from 'axios';
import FeedbackModal from './FeedbackModal.vue';
import FeedbackList from './FeedbackList.vue';

const route = useRoute();

interface RunData {
  run_id: number;
  deal_id: string;
  name: string;
  status: string;
  start_time: string;
  end_time: string;
  data: string;
}

interface DecisionData {
  decisions: any[];
  metadata: {
    pipeline_id: string;
    run_id: number;
  };
  bot: {
    msg: string;
    customer_msg: string;
    hyperlinks: Array<{ text: string; link: string }>;
  };
}

const runData = ref<RunData | null>(null);
const decisionData = ref<DecisionData | null>(null);
const isLoading = ref(false);
const error = ref<string | null>(null);

const showFeedbackModal = ref(false);
const feedbackType = ref('');

const fetchRunData = async (runId: string) => {
  isLoading.value = true;
  error.value = null;
  try {
    const response = await axios.get<RunData>(`${import.meta.env.VITE_CRM_API_HOST}/llm-runs/${runId}/`, {
      headers: {
        'X-CSRFToken': '0sB3h5DgT6ZZVUf11Jt0VmvTJ2mpxcxo0Y6L1C7wC3eOPIhLWUSC7cV7TRMgudOx'
      }
    });
    runData.value = response.data;
    decisionData.value = JSON.parse(runData.value.data) as DecisionData;
  } catch (err) {
    error.value = err instanceof Error ? err.message : 'An unknown error occurred';
  } finally {
    isLoading.value = false;
  }
};

const openFeedbackModal = (type: string) => {
  feedbackType.value = type;
  showFeedbackModal.value = true;
};

const closeFeedbackModal = () => {
  showFeedbackModal.value = false;
};

const submitFeedback = (feedbackData: any) => {
  console.log('Feedback submitted:', feedbackData);
  closeFeedbackModal();
  // Refresh the feedback list after submitting new feedback
  if (runData.value) {
    fetchRunData(runData.value.run_id.toString());
  }
};

onMounted(() => {
  const runId = route.params.runId;
  if (typeof runId === 'string') {
    fetchRunData(runId);
  } else {
    error.value = 'Invalid run ID';
  }
});

watch(() => route.params.runId, (newRunId) => {
  if (typeof newRunId === 'string') {
    fetchRunData(newRunId);
  } else {
    error.value = 'Invalid run ID';
  }
});
</script>

<style scoped>
.decision-result {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.run-data, .decision-data {
  background-color: #f5f5f5;
  padding: 15px;
  border-radius: 5px;
  margin-top: 20px;
}

.decision {
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
  background-color: white;
}

.bot-message {
  margin-top: 20px;
}

.feedback-section {
  margin-top: 20px;
}

.feedback-section button {
  margin-right: 10px;
  padding: 5px 10px;
  cursor: pointer;
}
</style>
