// NoteForm.vue
<template>
  <div class="note-form" @keydown.esc="cancelNote" @keydown.enter.prevent="saveNote">
    <div v-if="loadingTags">Loading tags...</div>
    <div v-else-if="tagError">Error loading tags: {{ tagError }}</div>
    <div v-else class="tag-section">
      <div class="tag-input">
        <input
          v-model="newTag"
          @keyup.enter="addTag"
          placeholder="Add new tag"
        />
        <button @click="addTag" class="add-tag-btn">+</button>
      </div>
      <div class="tag-list">
        <span
          v-for="tag in availableTags"
          :key="tag.id"
          @click="toggleTag(tag)"
          :class="['tag', { 'selected': selectedTags.includes(tag.id) }]"
        >
          {{ tag.name }}
        </span>
      </div>
    </div>
    <div class="section-label">Selected Text:</div>
    <div class="selected-text">
      {{ selectedText }}
    </div>
    <div class="section-label">Your Note:</div>
    <textarea
      v-model="noteText"
      placeholder="Enter your note here..."
      class="note-input"
    ></textarea>
    <div class="button-group">
      <button @click="saveNote" class="save-btn">Save</button>
      <button @click="cancelNote" class="cancel-btn">Cancel</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'NoteForm',
  props: {
    selectedText: {
      type: String,
      required: true
    },
    selectionStart: {
      type: Number,
      required: true
    },
    selectionEnd: {
      type: Number,
      required: true
    },
    availableTags: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      noteText: '',
      newTag: '',
      selectedTags: [],
      loadingTags: false,
      tagError: null,
    };
  },
  methods: {
    async saveNote() {
      if (this.noteText) {
        try {
          const response = await axios.post(`${import.meta.env.VITE_CRM_API_HOST}/notes/`, {
            text: this.selectedText,
            comment: this.noteText,
            selection_start: this.selectionStart,
            selection_end: this.selectionEnd,
            tags: this.selectedTags.map(tagId => {
              const tag = this.availableTags.find(t => t.id === tagId);
              return { name: tag.name };
            })
          });
          
          this.$emit('save', response.data);
          this.resetForm();
        } catch (error) {
          console.error('Failed to save note:', error);
          // You might want to show an error message to the user here
        }
      }
    },
    cancelNote() {
      this.$emit('cancel');
      this.resetForm();
    },
    resetForm() {
      this.noteText = '';
      this.selectedTags = [];
    },
    async addTag() {
      if (this.newTag && !this.availableTags.some(tag => tag.name === this.newTag)) {
        try {
          const response = await axios.post('http://127.0.0.1:8027/tags/', { name: this.newTag });
          this.$emit('tag-added', response.data);
          this.selectedTags.push(response.data.id);
          this.newTag = '';
        } catch (error) {
          console.error('Failed to add new tag:', error);
          // You might want to show an error message to the user here
        }
      }
    },
    toggleTag(tag) {
      const index = this.selectedTags.indexOf(tag.id);
      if (index === -1) {
        this.selectedTags.push(tag.id);
      } else {
        this.selectedTags.splice(index, 1);
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$el.querySelector('.note-input').focus();
    });
  }
};
</script>
<style scoped>
.note-form {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 8px;
  z-index: 1001;
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 350px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 14px;
}

.tag-section {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
}

.tag-input {
  display: flex;
  gap: 5px;
  width: 100%;
}

.tag-input input {
  flex-grow: 1;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.add-tag-btn {
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
}

.tag-list {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  justify-content: flex-start;
}

.tag {
  background-color: #f0f0f0;
  padding: 3px 8px;
  border-radius: 12px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.tag.selected {
  background-color: #4CAF50;
  color: white;
}

.section-label {
  font-weight: bold;
  text-align: left;
}

.selected-text {
  background-color: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 10px;
  max-height: 100px;
  overflow-y: auto;
  white-space: pre-wrap;
  word-break: break-word;
  text-align: left;
}

.note-input {
  width: 100%;
  height: 100px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  text-align: left;
}

.button-group {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
}

.save-btn, .cancel-btn {
  padding: 8px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.save-btn {
  background-color: #4CAF50;
  color: white;
}

.save-btn:hover {
  background-color: #45a049;
}

.cancel-btn {
  background-color: #f44336;
  color: white;
}

.cancel-btn:hover {
  background-color: #da190b;
}
</style>