<template>
  <div class="span">
    <div class="span-header" @click="toggleExpanded">
      <div class="vertical-lines" :style="{ width: `${depth * 20}px` }">
        <div v-for="n in depth" :key="n" class="vertical-line"></div>
      </div>
      <span class="expand-icon">{{ isExpanded ? '▼' : '▶' }}</span>
      <span :class="['span-icon', spanIconClass]">{{ spanIcon }}</span>
      <span class="operation-name">{{ span.message }}</span>
      <div class="feedback-buttons">
        <button @click.stop="like" :class="{ 'active': feedback.liked }">👍</button>
        <button @click.stop="openFeedbackModal('dislike')" :class="{ 'active': feedback.disliked }">👎</button>
        <button @click.stop="openFeedbackModal('comment')">💬</button>
      </div>
    </div>
    <div v-if="isExpanded" class="span-content">
      <div class="span-metadata">
        <span class="metadata-item">
          <span class="metadata-label">spanId:</span> {{ span.spanId }}
        </span>
        <span class="metadata-item">
          <span class="metadata-label">duration:</span> {{ formatDuration(span.duration_ms) }}
        </span>
        <span class="metadata-item">
          <span class="metadata-label">created:</span> {{ formatCreationDate(span.created) }}
        </span>
      </div>
      <div class="content-divider"></div>
      <div v-for="(event, index) in span.events" :key="index" class="event-container">
        <SpanContent :event="event" />
      </div>
      <div v-if="span.events.length === 0">
        <pre>{{ JSON.stringify(span.attributes, null, 2) }}</pre>
      </div>
    </div>
    <div v-if="span.childSpans.length > 0" class="span-children">
      <span-component
        v-for="childSpan in span.childSpans"
        :key="childSpan.spanId"
        :span="childSpan"
        :depth="depth + 1"
      />
    </div>
    <feedback-modal
      v-if="showFeedbackModal"
      :type="feedbackType"
      :common-errors="commonErrors"
      :span-id="span.spanId"
      :span-operation="span.message"
      @close="closeFeedbackModal"
      @submit="submitFeedback"
    />
  </div>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue';
import SpanContent from './SpanContent.vue';
// import FeedbackModal from './FeedbackModal.vue';
import type { Span } from '@/types/pipeline-models';

interface SpanComponentProps {
  span: Span;
  depth?: number;
}

const props = withDefaults(defineProps<SpanComponentProps>(), {
  depth: 0,
});

const isExpanded = ref(false);
const feedback = ref({ liked: false, disliked: false });
const showFeedbackModal = ref(false);
const feedbackType = ref('');
const commonErrors = [
  'Incorrect information',
  'Incomplete response',
  'Formatting issues',
  'Performance problems',
  'Other'
];

const spanType = computed(() => props.span.attributes.span_type as string || 'unknown');

const spanIcon = computed(() => {
  switch (spanType.value) {
    case 'reasoning': return '🧠';
    case 'llm_assistant': return '🤖';
    case 'email': return '✉️';
    case 'database_query': return '🗄️';
    case 'root': return '🌳';
    default: return '❓';
  }
});

const spanIconClass = computed(() => `span-icon-${spanType.value}`);

const toggleExpanded = () => {
  isExpanded.value = !isExpanded.value;
};

const formatDuration = (duration: number) => {
  return `${Math.round(duration)}ms`;
};

const formatCreationDate = (timestamp: Date) => {
  return timestamp.toLocaleString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false
  }).replace(',', '');
};

const like = () => {
  feedback.value.liked = !feedback.value.liked;
  feedback.value.disliked = false;
};

const openFeedbackModal = (type: string) => {
  feedbackType.value = type;
  showFeedbackModal.value = true;
  if (type === 'dislike') {
    feedback.value.disliked = true;
    feedback.value.liked = false;
  }
};

const closeFeedbackModal = () => {
  showFeedbackModal.value = false;
};

const submitFeedback = (feedbackData: any) => {
  console.log('Feedback submitted:', feedbackData);
  closeFeedbackModal();
};
</script>

<style scoped>
.span {
  margin-bottom: 5px;
}

.span-header {
  padding: 5px 10px;
  border: 1px solid #ddd;
  background-color: #f0f0f0;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.span-header:hover {
  background-color: #e0e0e0;
}

.vertical-lines {
  display: flex;
  margin-right: 5px;
}

.vertical-line {
  width: 20px;
  position: relative;
}

.vertical-line::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  border-left: 1px solid #ccc;
}

.expand-icon {
  margin-right: 5px;
  font-size: 0.8em;
}

.span-icon {
  margin-right: 5px;
  font-size: 1.2em;
}

.operation-name {
  flex-grow: 1;
}

.span-content {
  margin-left: 20px;
  padding: 10px;
  background-color: #fff;
}

.span-metadata {
  font-size: 0.9em;
  color: #666;
  margin-bottom: 10px;
}

.metadata-item {
  margin-right: 15px;
}

.metadata-label {
  font-weight: bold;
}

.content-divider {
  height: 1px;
  background-color: #ddd;
  margin: 10px 0;
}

.span-children {
  margin-left: 20px;
}

.feedback-buttons {
  display: flex;
  gap: 5px;
  margin-left: auto;
}

.feedback-buttons button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  padding: 2px 5px;
  border-radius: 4px;
}

.feedback-buttons button:hover {
  background-color: #e0e0e0;
}

.feedback-buttons button.active {
  background-color: #007bff;
  color: white;
}
</style>
