import { createRouter, createWebHistory } from 'vue-router';
import TraceViewer from '../components/builder/TraceViewer.vue';
import DiscountDecision from '../components/DiscountDecision.vue';
import DiscountRunsList from '../components/DiscountRunsList.vue';
import DecisionResult from '../components/DecisionResult.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: TraceViewer
  },
  {
    path: '/discount',
    name: 'DiscountDecision',
    component: DiscountDecision
  },
  {
    path: '/discount-runs',
    name: 'DiscountRunsList',
    component: DiscountRunsList
  },
  {
    path: '/decision-result/:runId',
    name: 'DecisionResult',
    component: DecisionResult
  },
  {
    path: '/trace/:traceId',
    name: 'TraceViewer',
    component: TraceViewer,
    props: true,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;