<template>
  <div class="log-item" :class="{ expanded: isExpanded }" :style="logItemStyle">
    <div class="log-header" @click="toggleExpanded">
      <div class="operation-type">{{ event.operation_name }}</div>
      <div class="message">{{ event.message }}</div>
      <span class="expand-icon">{{ isExpanded ? '▼' : '▶' }}</span>
    </div>
    <div v-if="isExpanded" class="log-details">
      <component
        v-for="(comp, index) in event.components"
        :key="index"
        :is="getComponentType(comp.type)"
        v-bind="comp.props"
        :style="getComponentStyle(comp)"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted, watch } from 'vue';
import TextField from './TextField.vue';
import Attributes from './Attributes.vue';
import Note from './Note.vue';
import References from './References.vue';
import RecursiveTable from './RecursiveTable.vue';
import type { SpanEvent, Component } from '@/types/pipeline-models';

const props = defineProps<{
  event: SpanEvent;
}>();

const isExpanded = ref(false);

const toggleExpanded = () => {
  isExpanded.value = !isExpanded.value;
};

const getComponentType = (type: string) => {
  switch (type) {
    case 'TextField': return TextField;
    case 'Attributes': return Attributes;
    case 'Note': return Note;
    case 'References': return References;
    case 'Table': return RecursiveTable;
    default: return null;
  }
};

const logItemStyle = computed(() => ({
  ...props.event.style,
  borderColor: props.event.eventCustomStyle?.borderColor || '#b8daff',
  backgroundColor: props.event.eventCustomStyle?.headerColor || '#e6f3ff'
}));

const getComponentStyle = (comp: Component) => ({
  ...comp.style,
  border: `${props.event.eventCustomStyle?.borderColor || '#b8daff'}`
});

// Log comp.props to console
const logComponentProps = () => {
  props.event.components.forEach((comp, index) => {
    console.log(`Component ${index} (${comp.type}) props:`, JSON.stringify(comp.props, null, 2));
  });
};

onMounted(() => {
  logComponentProps();
});

watch(() => props.event, () => {
  logComponentProps();
}, { deep: true });
</script>

<style scoped>
.log-item {
  margin-bottom: 8px;
  border: 1px solid;
  border-radius: 4px;
  overflow: hidden;
}

.log-header {
  cursor: pointer;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.operation-type {
  font-size: 0.8em;
  background-color: rgba(255, 255, 255, 0.5);
  color: #333;
  padding: 2px 6px;
  border-radius: 3px;
  margin-bottom: 4px;
}

.message {
  font-size: 0.9em;
  font-weight: bold;
}

.expand-icon {
  font-size: 0.8em;
  color: #555;
  align-self: flex-end;
  margin-top: -20px;
}

.log-details {
  padding: 8px 12px;
  background-color: #fff;
  border-top: 1px solid;
  border-color: inherit;
}
</style>