<template>
  <div v-if="loading">Loading...</div>
  <div v-else-if="error">{{ error }}</div>
  <div v-else-if="trace">
    <div v-for="span in trace.spans" :key="span.spanID" class="span" :style="spanStyle">
      <div class="span-header" @click="toggleExpanded(span.spanID)">
        <span class="expand-icon">{{ isExpanded(span.spanID) ? '▼' : '▶' }}</span>
        <span class="span-icon">{{ span.operationName }}</span>
        <span class="operation-name">{{ getSpanMessage(span) }}</span>
      </div>
      <div v-if="isExpanded(span.spanID)" class="span-content">
        <div class="span-metadata">
          <span class="metadata-item">
            <span class="metadata-label">operation:</span> {{ span.operationName }}
          </span>
          <span class="metadata-item">
            <span class="metadata-label">event_type:</span> {{ getEventType(span) }}
          </span>
        </div>
        <div class="content-divider"></div>
        <component
          v-for="(comp, index) in getSpanComponents(span)"
          :key="index"
          :is="getComponentType(comp.type)"
          v-bind="comp.props"
          :style="comp.style"
        />
      </div>
    </div>
  </div>
  <div v-else>No trace data available</div>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue';
import { useTrace } from '@/otel/useTrace';
import TextField from './TextField.vue';
import Attributes from './Attributes.vue';
import Note from './Note.vue';
import References from './References.vue';
import type { ParsedSpan, Component } from '@/otel/trace-interfaces';


export interface ParsedLog {
  timestamp: number;
  fields: Array<{
    key: string;
    value: string;
  }>;
}


const props = defineProps<{
  traceId: string;
  customStyle?: {
    headerColor?: string;
    borderColor?: string;
  };
}>();


const { trace, uiComponents, error, loading } = useTrace(props.traceId);

const expandedSpans = ref<Set<string>>(new Set());

const toggleExpanded = (spanId: string) => {
  if (expandedSpans.value.has(spanId)) {
    expandedSpans.value.delete(spanId);
  } else {
    expandedSpans.value.add(spanId);
  }
};

const isExpanded = (spanId: string) => expandedSpans.value.has(spanId);

const getComponentType = (type: string) => {
  switch (type) {
    case 'TextField': return TextField;
    case 'Attributes': return Attributes;
    case 'Note': return Note;
    case 'References': return References;
    default: return null;
  }
};

const spanStyle = computed(() => ({
  borderColor: props.customStyle?.borderColor || '#ddd',
}));

const getSpanMessage = (span: ParsedSpan): string => {
  const messageField = span.logs[0]?.fields.find(field => field.key === 'message');
  return messageField?.value || span.operationName;
};

const getEventType = (span: ParsedSpan): string => {
  const eventTypeField = span.logs[0]?.fields.find(field => field.key === 'event_type');
  return eventTypeField?.value || 'Unknown';
};

const getSpanComponents = (span: ParsedSpan): Component[] => {
      const components: Component[] = [];
      span.logs.forEach((log: ParsedLog) => {
        const uiComponentsField = log.fields.find(field => field.key === 'ui_components');
        if (uiComponentsField) {
          try {
            const parsedComponents = JSON.parse(uiComponentsField.value);
            if (Array.isArray(parsedComponents.components)) {
              components.push(...parsedComponents.components);
            }
          } catch (error) {
            console.error('Error parsing ui_components:', error);
          }
        }
      });
      return components;
    };
</script>

<style scoped>
.span {
  margin-bottom: 5px;
}
.span-header {
  padding: 5px 10px;
  border: 1px solid;
  background-color: v-bind('customStyle?.headerColor || "#f0f0f0"');
  cursor: pointer;
  display: flex;
  align-items: center;
}
.span-header:hover {
  background-color: #e0e0e0;
}
.expand-icon {
  margin-right: 5px;
  font-size: 0.8em;
}
.span-icon {
  margin-right: 5px;
  font-size: 1.2em;
}
.operation-name {
  flex-grow: 1;
}
.span-content {
  padding: 10px;
  border: 1px solid;
  border-color: v-bind('customStyle?.borderColor || "#ddd"');
  border-top: none;
  background-color: #fff;
}
.span-metadata {
  font-size: 0.9em;
  color: #666;
  margin-bottom: 10px;
}
.metadata-item {
  margin-right: 15px;
}
.metadata-label {
  font-weight: bold;
}
.content-divider {
  height: 1px;
  background-color: #ddd;
  margin: 10px 0;
}
</style>